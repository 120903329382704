import { axiosErrorHandler } from 'app/modules/errors/ApiError'
import {Staff2, type Staff, type StaffQueryResponse, Staff2QueryResponse} from './_models'
import axios, {type AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MESSAGES_URL = `${API_URL}/messages`
const STAFF_URL = `${API_URL}/staffs`
const FORMDATA_URL = `${API_URL}/formsdatas`

const getStaffsTo = async (value: string): Promise<Staff[]> => {
  return await axios
    .post(`${MESSAGES_URL}/selectto`, {
      name: value,
    })
    .then((response: AxiosResponse<StaffQueryResponse>) => {
      return response.data as Staff[]
    })
}

const getStaffs = async (staff: Staff): Promise<Staff[]> => {
  return await axios
    .post(`${STAFF_URL}/all`, {
      ...staff,
    })
    .then((response: AxiosResponse<StaffQueryResponse>) => {
      return response.data as Staff[]
    })
}

const getAssigned = async (staffs: any): Promise<any> => {
  return await axios
    .post(`${STAFF_URL}/assigned`, {
      staffs,
    })
    .then((response: AxiosResponse<any>) => {
      return response.data
    })
}

const getStaff = async (id: string): Promise<Staff2[]> => {
  return await axios
    .post(`${STAFF_URL}/getstaff/${id}`)
    .then((response: AxiosResponse<Staff2QueryResponse>) => {
      return response.data as Staff2[]
    })
}

const getAllStaff = async (): Promise<any> => {
  return await axios
    .get(`${STAFF_URL}/all`)
    .then((response: AxiosResponse<Staff2QueryResponse>) => {
      return response.data as any
    })
}

const getDataByEnvelopeId = async (id:string): Promise<any> => {
  return await axios
    .get(`${FORMDATA_URL}/getonebyenvelopeid/${id}`)
    .then((response: AxiosResponse<Staff2QueryResponse>) => {
      return response.data as any
    })
}

const updateStaff = async (id: string, data: object): Promise<any> => {
  return await axios
    .patch(`${STAFF_URL}/upt/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      axiosErrorHandler(error, 'Error al actualizar ')
    })
}

export {getStaffsTo, getStaffs, getStaff, getAllStaff, getAssigned, updateStaff,getDataByEnvelopeId}
